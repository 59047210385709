import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseStatisticRatingCategoryType} from "../../types/ResponseStatisticRatingCategoryType"
import {getUrl} from "utils/api-config"

interface ArgsProps {
  date_time_from?: string
  date_time_to?: string
  branch_id?: string
  rating_category_id_list?: number[] | null
  excluded_rating_category_id_list?: number[] | null
}

export const fetchByRatingCategoryType = createAsyncThunk<
  ResponseStatisticRatingCategoryType,
  ArgsProps,
  AppThunkProps
>("ratingStatistic/fetchByRatingCategoryType", async (data, {signal, getState}) => {
  // Получения данных авторизации
  const {auth} = getState()
  // Запрос
  const response = await fetch(getUrl(`${process.env.REACT_APP_API_REVIEW}/statistics/rating`, data), {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.token}`,
      "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
    },
    signal
  })
  return await response.json()
})

export const downloadRatingStatistics = createAsyncThunk<Record<string, never>, ArgsProps, AppThunkProps>(
  "statistics/rating/download",
  async (data, {signal, getState}) => {
    // Получения данных авторизации
    const {auth} = getState()

    // Запрос
    const response = await fetch(getUrl(`${process.env.REACT_APP_API_REVIEW}/statistics/rating/download`, data), {
      method: "get",
      headers: {
        Accept: "multipart/form-data",
        Authorization: `Bearer ${auth.token}`,
        "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
      },
      signal
    })

    const resp = await response.blob()
    const urlCreator = window.URL || window.webkitURL
    const url = urlCreator.createObjectURL(resp)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = "statistics-by-rating.xlsx"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)

    return await response.json()
  }
)
