import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";
import { ResponseStatisticRatingCategoryType } from "../../types/ResponseStatisticRatingCategoryType";
import { fetchByRatingCategoryType } from "./fetchByRatingCategoryType";

interface ElementProps {
    rating: number;
    total: number;
}

interface StatisticsProps {
    average: number;
    type?: string;
    elements: ElementProps[];
}

interface StateProps {
    loading: boolean;
    statistics: ResponseStatisticRatingCategoryType["data"][0]["statistics"];
    total_statistics: ResponseStatisticRatingCategoryType["data"][0]["total_statistics"];
    not_assigned_statistics: ResponseStatisticRatingCategoryType["data"][0]["not_assigned_statistics"];
}

const emptyStatistics: StatisticsProps = {
    average: 0,
    type: "",
    elements: []
};

const initialState: StateProps = {
    loading: false,
    statistics: [],
    total_statistics: emptyStatistics,
    not_assigned_statistics: emptyStatistics
};

const ratingStatisticSlice = createSlice({
    name: "ratingByCategoryStatistic",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchByRatingCategoryType.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchByRatingCategoryType.fulfilled, (state, action) => {
            if (action.payload && action.payload.success) {
                state.statistics = action.payload.data[0].statistics
                state.total_statistics = action.payload.data[0].total_statistics
                state.not_assigned_statistics = action.payload.data[0].not_assigned_statistics
            } else {
                state.statistics = []
                state.total_statistics = emptyStatistics;
                state.not_assigned_statistics = emptyStatistics;
            }
            state.loading = false
        })
        builder.addCase(fetchByRatingCategoryType.rejected, state => {
            state.loading = false
        })
    }
})

export default ratingStatisticSlice.reducer

export const useGetRatingCategoryType = () =>
  useSelector((state: StoreState) => state.ratingByCategoryType)
