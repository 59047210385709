import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseFeedbacks} from "types/ResponseFeedbacks"

export interface ArgsProps {
  page: number
  size: number
  status?: string
  branch_ids?: string[]
  missed_branch?: boolean
  ratings?: number[]
  source_ids?: number[]
  assigned_employee_ids?: number[]
  customer?: string
  date_time_from?: string
  date_time_to?: string
  product_ratings?: number[]
  service_ratings?: number[]
  resolution_category_ids?: number[]
  number?: number
  rating_category_ids?: number[]
  excluded_rating_category_ids?: number[]
  has_audio?: "true" | "false"
}

type ReturnedType = ResponseFeedbacks

export const fetchFeedbacks = createAsyncThunk<ReturnedType, ArgsProps, AppThunkProps>(
  "feedbacks/fetch",
  async (data, {signal, getState}) => {
    // Получения данных авторизации
    const {auth} = getState()
    // Запрос
    const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/v2/feedbacks-search`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
      },
      signal
    })
    return await response.json()
  }
)

export const fetchDownloadFeedbacks = createAsyncThunk<ReturnedType, ArgsProps, AppThunkProps>(
  "feedbacks/download",
  async (data, {signal, getState}) => {
    // Получения данных авторизации
    const {auth} = getState()
    // Запрос
    const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/feedbacks/download`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
      },
      signal
    })

    const resp = await response.blob()
    const urlCreator = window.URL || window.webkitURL
    const url = urlCreator.createObjectURL(resp)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = "feedback.xlsx"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)

    return await response.json()
  }
)
